import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/Layout/index.vue'
import Index from '@/views/index/index.vue'

Vue.use(VueRouter)

const oldHost = process.env.VUE_APP_OLD_HOST

const routes = [
  {
    path: '',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'Index',
        component: Index
      },
      {
        path: '/about',
        name: 'About',
        // component: () => import("@/views/about/index.vue"),
        beforeEnter (to: any) {
          location.href = `${oldHost}/about.html?id=${to.query.id}`
        }
      },
      // {
      //   path: "/show",
      //   name: "Show",
      //   component: () => import("@/views/about/show.vue"),
      // },
      {
        path: '/news_notice',
        name: 'NewsNotice',
        // component: () => import("@/views/news/notice.vue"),
        beforeEnter (to: any) {
          location.href = `${oldHost}/news.html?id=${to.query.type}`
        }
      },
      {
        path: '/news_video',
        name: 'NewsVideo',
        // component: () => import("@/views/news/video.vue"),
        beforeEnter () {
          location.href = `${oldHost}/news.html?id=4`
        }
      },
      {
        path: '/news_image',
        name: 'NewsImage',
        // component: () => import("@/views/news/image.vue"),
        beforeEnter () {
          location.href = `${oldHost}/news.html?id=5`
        }
      },
      // {
      //   path: "/news_detail",
      //   name: "NewsDetail",
      //   component: () => import("@/views/news/notice-detail.vue"),
      // },
      // {
      //   path: "/image_detail",
      //   name: "ImageDetail",
      //   component: () => import("@/views/news/image-detail.vue"),
      // },
      {
        path: '/conference_notice',
        name: 'ConferenceNotice',
        // component: () => import("@/views/conference/notice.vue"),
        beforeEnter () {
          location.href = `${oldHost}/zhanhui.html?id=2`
        }
      },
      {
        path: '/conference_schedule',
        name: 'ConferenceSchedule',
        // component: () => import("@/views/conference/schedule.vue"),
        beforeEnter () {
          location.href = `${oldHost}/zhanhui_rc.html?id=3`
        }
      },
      {
        path: '/conference_broadcast',
        name: 'ConferenceBroadcast',
        // component: () => import("@/views/conference/broadcast.vue"),
        beforeEnter () {
          location.href = `${oldHost}/zhanhui_live.html?id=5`
        }
      },
      // {
      //   path: "/conference_broadcast_detail",
      //   name: "ConferenceBroadcastDetail",
      //   component: () => import("@/views/conference/broadcast-detail.vue"),
      // },
      {
        path: '/conference_guide',
        name: 'ConferenceGuide',
        // component: () => import("@/views/conference/guide.vue"),
        beforeEnter () {
          location.href = `${oldHost}/zhanhuidl.html?id=6`
        }
      },
      {
        path: '/conference_guideline',
        name: 'ConferenceGuideline',
        // component: () => import("@/views/conference/guideline.vue"),
        beforeEnter () {
          location.href = `${oldHost}/zhanhui_guide.html?id=7`
        }
      },
      {
        path: '/online_display',
        name: 'OnlineDisplay',
        // component: () => import("@/views/online/display.vue"),
        beforeEnter () {
          location.href = `${oldHost}/zhanting_citys.html`
        }
      },
      {
        path: '/online_industry',
        name: 'OnlineIndustry',
        // component: () => import("@/views/online/industry.vue"),
        beforeEnter () {
          location.href = `${oldHost}/zhanting_citys2.html`
        }
      },
      {
        path: '/online_organization',
        name: 'OnlineOrganization',
        // component: () => import("@/views/online/organization.vue"),
        beforeEnter (to: any) {
          location.href = `${oldHost}/zhanting_list.html?id=${to.query.eh_id}`
        }
      },
      {
        path: '/online_recommend',
        name: 'OnlineRecommend',
        // component: () => import("@/views/online/recommend.vue"),
        beforeEnter () {
          location.href = `${oldHost}/zhanting.html?id=4`
        }
      },
      // {
      //   path: "/online_platform",
      //   name: "OnlinePlatform",
      //   component: () => import("@/views/online/platform.vue"),
      // },
      {
        path: '/online_show',
        name: 'OnlineShow',
        // component: () => import("@/views/online/show.vue"),
        beforeEnter () {
          location.href = `${oldHost}/online_road_show.html`
        }
      },
      // {
      //   path: "/show_detail",
      //   name: "ShowDetail",
      //   component: () => import("@/views/online/show-detail.vue"),
      // },
      {
        path: '/competition_answer',
        name: 'CompetitionAnswer',
        // component: () => import("@/views/competition/answer.vue"),
        beforeEnter () {
          location.href = `${oldHost}/ziyuan1.html?type=3`
        }
      },
      // {
      //   path: "/competition_contact",
      //   name: "CompetitionContact",
      //   component: () => import("@/views/competition/contact.vue"),
      // },
      {
        path: '/competition_highlight',
        name: 'CompetitionHighlight',
        // component: () => import("@/views/competition/highlight.vue"),
        beforeEnter () {
          location.href = `${oldHost}/ziyuan1.html?type=4`
        }
      },
      {
        path: '/competition_info',
        name: 'CompetitionInfo',
        // component: () => import("@/views/competition/info.vue"),
        beforeEnter (to: any) {
          const { query } = to
          const { id } = query
          if (id === '176') {
            location.href = `${oldHost}/ziyuan1.html?type=2`
          } else if (id === '177') {
            location.href = `${oldHost}/ziyuan1.html?type=4`
          }
        }
      },
      {
        path: '/competition_player',
        name: 'CompetitionPlayer',
        // component: () => import("@/views/competition/player.vue"),
        beforeEnter () {
          location.href = `${oldHost}/ziyuan1.html?type=1`
        }
      },
      {
        path: '/forum_introduce',
        name: 'ForumIntroduce',
        // component: () => import("@/views/forum/introduce.vue"),
        beforeEnter (to: any) {
          const { query } = to
          const { id } = query
          if (id === '167') {
            location.href = `${oldHost}/luntan1.html`
          } else if (id === '170') {
            location.href = `${oldHost}/ydyl1.html`
          } else if (id === '173') {
            location.href = `${oldHost}/jlh1.html`
          }
        }
      },
      // {
      //   path: "/forum_guest",
      //   name: "ForumGuest",
      //   component: () => import("@/views/forum/guest.vue"),
      // },
      // {
      //   path: "/forum_live",
      //   name: "ForumLive",
      //   component: () => import("@/views/forum/live.vue"),
      // },
      {
        path: '/service',
        name: 'Service',
        // component: () => import("@/views/service/index.vue"),
        beforeEnter (to: any) {
          location.href = `${oldHost}/servers.html?id=${to.query.id}`
        }
      },
      {
        path: '/discuss_transaction',
        name: 'DiscussTransaction',
        // component: () => import("@/views/discuss/transaction.vue"),
        beforeEnter () {
          location.href = `${oldHost}/qiatan.html?id=1`
        }
      },
      {
        path: '/discuss_notice',
        name: 'discussNotice',
        // component: () => import("@/views/discuss/notice.vue"),
        beforeEnter (to: any) {
          location.href = `${oldHost}/qiatan1.html?type=${to.query.id}`
        }
      }
      // {
      //   path: "/login",
      //   name: "Login",
      //   component: () => import("@/views/login/login.vue"),
      // },
      // {
      //   path: "/register",
      //   name: "Register",
      //   component: () => import("@/views/login/register.vue"),
      // },
      // {
      //   path: "/privacy",
      //   name: "Privacy",
      //   component: () => import("@/views/login/privacy.vue"),
      // },
      // {
      //   path: "/protocol",
      //   name: "Protocol",
      //   component: () => import("@/views/login/protocol.vue"),
      // },
      // {
      //   path: "/retrieve",
      //   name: "Retrieve",
      //   component: () => import("@/views/login/retrieve.vue"),
      // },
      // {
      //   path: "/merchant",
      //   name: "Merchant",
      //   component: () => import("@/views/apply/merchant.vue"),
      // },
      // {
      //   path: "/audience",
      //   name: "Audience",
      //   component: () => import("@/views/apply/audience.vue"),
      // },
      // {
      //   path: "/industry",
      //   name: "Industry",
      //   component: () => import("@/views/apply/industry.vue"),
      // },
      // {
      //   path: "/information",
      //   name: "Information",
      //   component: () => import("@/views/personal/information.vue"),
      //   children: [
      //     { path: '/', name: "Information", component: () => import("@/views/personal/components/Information.vue") },
      //     { path: 'account', name: "Account", component: () => import("@/views/personal/components/Account.vue") },
      //     { path: 'entrance', name: "Entrance", component: () => import("@/views/personal/components/Entrance.vue") },
      //     { path: 'record', name: "Record", component: () => import("@/views/personal/components/Record.vue") },
      //     { path: 'manager', name: "Manager", component: () => import("@/views/personal/components/Manager.vue") },
      //     { path: 'host', name: "Host", component: () => import("@/views/personal/components/Host.vue") },
      //     { path: 'focus', name: "Focus", component: () => import("@/views/personal/components/Focus.vue") },
      //   ]
      // },
      // {
      //   path: "/sign_detail",
      //   name: "SignDetail",
      //   component: () => import("@/views/personal/sign-detail.vue"),
      // },
    ]
  },
  {
    path: '/online_3d',
    name: 'Online3D',
    // component: () => import('@/views/online/3d.vue'),
    beforeEnter () {
      location.href = 'http://3d.ncdhrs.com/vr/NzQxNTY4YzktOTJmNi1lZmM2LTI4ODAtZDkxNmU2YTc4MWNj.html'
    }
  },
  {
    path: '*',
    name: 'NotFound',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
