/*
 * @Descripttion:
 * @Author: xueshilun
 * @Date: 2021-06-30 15:13:43
 * @LastEditors: xueshilun
 * @LastEditTime: 2021-07-01 16:13:26
 */
import request from '@/utils/request'

/**
 * @name: 发展成果展\产业园展
 * @param {meet_id} params
 * @return {*}
 */
export const getCyyList = (params: any) =>
  request({
    url: '/meeting/cyy_lst',
    method: 'get',
    params
  })
/**
 * @name: 获取展会详情
 * @param {meet_id,id,member_id} params
 * @return {*}
 */
export const getExhibitormDetail = (params: any) =>
  request({
    url: '/meeting/exhibitorm_detail',
    method: 'get',
    params
  })
/**
 * @name: 获取展会列表
 * @param {meet_id,eh_id,member_id,type,page,page_size,region,class_id,booth_address,order} params
 * eh_id： 9-服务机构展，12-用人单位展
 * type: 1-要推荐
 * @return {*}
 */
export const getExhibitormList = (params: any) =>
  request({
    url: '/meeting/exhibitorm_lst',
    method: 'get',
    params
  })

/**
 * @name: 获取热门展会列表
 * @param {meet_id,eh_id,member_id,type,page,page_size,region,class_id,booth_address,order} params
 * eh_id： 9-服务机构展，12-用人单位展
 * type: 1-要推荐
 * @return {*}
 */
export const getHotExhibitormList = (params: any) =>
  request({
    url: '/meeting/hot_exhibitorm_lst',
    method: 'get',
    params
  })

/**
 * @name: 获取省份列表
 * @param {} params
 * @return {*}
 */
export const getProvinceList = (params: any) =>
  request({
    url: '/meeting/province_list',
    method: 'get',
    params
  })

/**
 * @name: 3D展厅列表
 * @param {} params
 * @return {*}
 */
export const get3dList = (params: any) =>
  request({
    url: '/meeting/get_exhibition3d',
    method: 'get',
    params
  })
