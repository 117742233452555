<template>
<div class="content">
  <div class="section">
    <swiper
        class="swiper"
        ref="mySwiper"
        :options="swiperOptions"
    >
      <swiper-slide><img class="banner" src="@/assets/index/banner1.jpg" alt=""></swiper-slide>
      <swiper-slide><img class="banner" src="@/assets/index/banner2.jpg" alt=""></swiper-slide>
      <swiper-slide><img class="banner" src="@/assets/index/banner3.jpg" alt=""></swiper-slide>
      <swiper-slide><img class="banner" src="@/assets/index/banner4.jpg" alt=""></swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
  <div class="section">
    <div class="container exhibitions">
      <router-link to="/online_display" class="exhibition-item">
        <img src="@/assets/index/exhibition1.png" alt=""/>
        <div class="title">发展成果展</div>
      </router-link>
      <router-link to="/online_industry" class="exhibition-item">
        <img src="@/assets/index/exhibition2.png" alt=""/>
        <div class="title">产业园展</div>
      </router-link>
      <router-link to="/online_organization?eh_id=9" class="exhibition-item">
        <img src="@/assets/index/exhibition3.png" alt=""/>
        <div class="title">服务机构展</div>
      </router-link>
      <router-link to="/online_organization?eh_id=12" class="exhibition-item">
        <img src="@/assets/index/exhibition4.png" alt=""/>
        <div class="title">用人单位展</div>
      </router-link>
    </div>
  </div>
  <div class="section">
    <div class="container cards">
      <a href="/online_3d" target="_blank" class="main-card" />
      <div class="sub-cards">
        <a :href="`${oldHost}/signup_exhibitor.html`" class="sub-card-item">
          <img src="@/assets/index/online-display-apply.png" alt="">
          <div class="title">线上参展申请</div>
        </a>
        <a :href="`${oldHost}/signup.html?iid=3`" class="sub-card-item">
          <img src="@/assets/index/demander-apply.png" alt="">
          <div class="title">需求方报名</div>
        </a>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="container news">
      <div class="top">
        <div class="top-title">大会资讯</div>
        <router-link to="/news_notice?type=2" class="view-more">更多资讯</router-link>
      </div>
      <div class="news-box">
        <div class="left">
          <swiper
              class="swiper"
              ref="mySwiper"
              :options="swiperOptions"
          >
            <swiper-slide
                :index="item.id"
                :key="item.id"
                v-for="item in newsBannerList"
            >
              <a :href="item.link_url" target="_blank">
                <img class="banner" :src="item.img_url" alt="">
                 <div class="mask">
                   {{ item.title }}
                 </div>
              </a>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <div class="right news-list">
          <div class="news-item" :key="item.id" v-for="item in newsList">
            <span class="type">{{ newsTypeMap[item.type] }}</span>
            <span class="line">|</span>
            <a :href="`${oldHost}/news.html?id=1&dataid=${item.id}`" target="_blank" class="substring title">{{ item.title }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="container exhibitors">
       <div class="top">
        <div class="top-title">推荐展商</div>
        <router-link to="/online_recommend" class="view-more">查看更多</router-link>
      </div>
      <div class="recommend-list">
        <a :href="`${oldHost}/zhanting_detail.html?dataid=${item.id}`" class="recommend-item" :key="item.id" v-for="item in exhibitorList">
          <img :src="item.logo" alt="">
          <div class="substring2 title">
            {{ item.company_name }}
          </div>
        </a>
        <div class="clear"></div>
      </div>
      <div class="top-title">热门展商</div>
      <div class="hot-list">
        <swiper class="swiper" :options="swiperHotOptions">
          <swiper-slide :key="item.id" v-for="item in exhibitorHotList">
            <a :href="`${oldHost}/zhanting_detail.html?dataid=${item.id}`" class="hot-item">
              <img :src="item.logo" alt="">
              <div class="substring2 title">
                {{ item.company_name }}
              </div>
            </a>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </div>
  </div>
  <div class="section stat">
    <div class="container">
      <div class="title">
        第一届人力资源服务业发展大会亮点
      </div>
      <div class="stat-list">
        <div class="stat-item">
          <div class="circle">
            <span class="num">166</span>
            <span class="unit">亿+</span>
          </div>
          <div class="des">
            供需对接项目签约总金额
          </div>
        </div>
        <div class="stat-item">
          <div class="circle">
            <span class="num">1000</span>
            <span class="unit">+</span>
          </div>
          <div class="des">
            大会参展单位
          </div>
        </div>
        <div class="stat-item">
          <div class="circle">
            <span class="num">5000</span>
            <span class="unit">+</span>
          </div>
          <div class="des">
            大会参会人员
          </div>
        </div>
        <div class="stat-item">
          <div class="circle">
            <span class="num">60000</span>
            <span class="unit">+</span>
          </div>
          <div class="des">
            行业大赛各省市选拔赛参赛总人数
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="container review">
      <div class="review-img">
        <a :href="`${oldHost}/1st.html`" class="btn">点击进入活动主页</a>
      </div>
    </div>
  </div>
  <div class="fix-box fix-list">
    <router-link to="/competition_answer" class="fix-item">
      <i class="iconfont icon-award"></i>
      <div class="title">有奖答题</div>
    </router-link>
    <a href="tel:010-86623456" class="fix-item">
      <i class="iconfont icon-phone-call"></i>
      <div class="title">服务热线</div>
      <div class="hover-box">
        010-86623456
      </div>
    </a>
    <a href="mailto:service@ncdhrs.com" class="fix-item">
      <i class="iconfont icon-email"></i>
      <div class="title">服务邮箱</div>
       <div class="hover-box">
        service@ncdhrs.com
      </div>
    </a>
    <div class="fix-item">
      <i class="iconfont icon-wechat"></i>
      <div class="title">官方微信</div>
      <div class="hover-box hover-qrcode-box">
        <img src="@/assets/index/qrcode1.jpeg" alt="">
        <div class="title">大会服务号</div>
        <img src="@/assets/index/qrcode2.jpeg" alt="">
        <div class="title">大会订阅号</div>
      </div>
    </div>
    <div class="fix-item" @click="backTop">
      <i class="iconfont icon-top"></i>
    </div>
  </div>
</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css'
import { getBanner } from '@/api/common.ts'
import { getNews } from '@/api/news.ts'
import { getExhibitormList, getHotExhibitormList } from '@/api/online.ts'

export default {
  name: 'index',
  data () {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        loop: true,
        parallax: true,
        autoplay: {
          delay: 3000
        }
      },
      swiperHotOptions: {
        slidesPerView: 5,
        spaceBetween: 30,
        loop: true,
        autoplay: {
          delay: 3000
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      newsBannerList: [],
      newsList: [],
      newsTypeMap: {
        1: '公告',
        2: '要闻',
        3: '动态'
      },
      exhibitorList: [],
      exhibitorHotList: [],
      oldHost: process.env.VUE_APP_OLD_HOST
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  created () {
    this.getNewsList()
    this.getNewsBanner()
    this.getExhibitorList()
    this.getHotExhibitorList()
  },
  methods: {
    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop () {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    async getNewsBanner () {
      const res = await getBanner({
        position: 11,
        page: 1,
        page_size: 100
      })
      if (res.code === 0) {
        this.newsBannerList = res.data.arr || []
      }
    },
    async getNewsList () {
      const res = await getNews({
        meet_id: 126,
        type: '1,2,3',
        page: 1,
        page_size: 7,
        iid: 0
      })
      if (res.code === 0) {
        console.info(res.data.arr)
        this.newsList = res.data.arr || []
      }
    },
    async getExhibitorList () {
      const userInfo = JSON.parse(localStorage.getItem('USERINFO'))
      const res = await getExhibitormList({
        meet_id: 126,
        member_id: userInfo ? userInfo.id : 1,
        type: 1,
        page: 1,
        page_size: 15
      })
      if (res.code === 0) {
        this.exhibitorList = res.data.arr || []
      }
    },
    async getHotExhibitorList () {
      const res = await getHotExhibitormList({
        meet_id: 126,
        type: 1,
        page: 1,
        page_size: 15
      })
      if (res.code === 0) {
        this.exhibitorHotList = res.data.arr || []
      }
    }
    // clickMainCard () {
    //   // this.$message({
    //   //   message: '敬请期待',
    //   //   center: true
    //   // })
    //   this.$router.push('/online_3d')
    // }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.content {
  width: 100%;
  min-width: 1230px;

  .fix-box {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    z-index: 999;
    background: white;
    border: 1px solid #d6d6d6;
    width: 66px;
    .fix-item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 15px 0;
      text-decoration: none;
      color: #2c3e50;
      position: relative;
      &:hover, &:first-child {
        color: #fff;
        background-color: $primary;
        .hover-box {
          display: block;
          &.hover-qrcode-box {
            display: flex;
          }
        }
      }
      .iconfont {
        font-size: 26px;
      }
      .title {
        font-size: 12px;
        text-align: center;
        margin-top: 5px;
      }
      .hover-box {
        border: 1px solid #d6d6d6;
        display: none;
        position: absolute;
        top: 10px;
        right: 75px;
        padding: 0 12px;
        line-height: 50px;
        background: white;
        white-space: nowrap;
        color: #2c3e50;
        &.hover-qrcode-box {
          flex-direction: column;
          padding: 8px;
          .title {
            margin: 0;
            line-height: 12px;
          }
        }
        a {
          color: #2c3e50;
          text-decoration: none;
        }
        img {
          width: 120px;
          height: 120px;
        }
      }
    }
  }

  .section {
    background-color: #fff;

    &:nth-child(2n) {
      background-color: #f6f6f6;
    }

    .top-title {
      font-size: 24px;
      text-align: left;
      font-weight: bold;
      position: relative;
      line-height: 56px;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 36px;
        height: 4px;
        background-color: $primary;
        left: 0;
      }
    }

    ::v-deep.swiper-container {
      height: 37.5vw;
      min-height: 461px;

      .swiper-pagination-bullet-active {
        width: 30px;
        border-radius: 4px;
      }

      .banner {
        height: 37.5vw;
        min-height: 461px;
        object-fit: cover;
        width: 100%;
      }
    }

    .exhibitions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 52px 15px;
      box-sizing: border-box;

      .exhibition-item {
        width: 268px;
        height: 268px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 8px;
        text-decoration: none;
        color: #333;

        &:hover {
          background-color: $primary;

          .title {
            color: #fff;
          }
        }

        img {
          width: 140px;
          margin-bottom: 26px;
        }

        .title {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

    .cards {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 72px 15px;
      box-sizing: border-box;

      .main-card {
        background-image: url("~@/assets/index/3D-display.png");
        width: 632px;
        height: 342px;
        background-position: center;
        background-size: cover;
        border-radius: 6px;
        cursor: pointer;
      }

      .sub-cards {
        height: 342px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .sub-card-item {
          width: 484px;
          height: 160px;
          background-color: #dcf0fe;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #333;
          text-decoration: none;

          img {
            margin-right: 52px;
            margin-left: 90px;
          }

          .title {
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }

    .news {
      padding: 72px 15px;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .view-more {
          color: $primary;
          cursor: pointer;
          text-decoration: none;
        }
      }

      .news-box {
        margin-top: 36px;
        display: flex;
        justify-content: space-between;

        .left {
          ::v-deep.swiper-container {
            width: 732px;
            height: 412px;
            position: relative;

            img {
              width: 732px;
              height: 412px;
            }

            &.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
              text-align: right;
              padding-right: 32px;
              box-sizing: border-box;
              bottom: 30px;
            }
          }

          .mask {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 732px;
            height: 80px;
            background-color: rgba(0, 0, 0, .6);
            z-index: 10;
            font-size: 20px;
            color: #fff;
            line-height: 80px;
            padding-left: 44px;
            font-weight: bold;
          }
        }

        .right {
          width: 432px;

          &.news-list {
            .news-item {
              margin-bottom: 18px;
              padding: 6px 12px;
              border-radius: 24px;
              font-size: 16px;
              display: flex;
              align-items: center;

              &:last-child {
                margin-bottom: 0;
              }

              &:hover {
                background-color: $primary;

                .type, .line, .title {
                  color: #fff;
                }

                .type::before {
                  background-color: #fff;
                }
              }

              .type {
                color: #999;

                &::before {
                  content: "";
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                  background-color: #999;
                  display: inline-block;
                  margin-right: 16px;
                }
              }

              .line {
                color: #999;
                margin-left: 8px;
                margin-right: 12px;
                display: inline-block;
              }

              .title {
                color: #333;
                font-weight: bold;
                text-decoration: none;
                display: inline-block;
                width: 320px;
              }
            }
          }
        }
      }
    }

    .exhibitors {
      padding: 64px 15px;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .view-more {
          color: $primary;
          cursor: pointer;
          text-decoration: none;
        }
      }

      .recommend-list {
        .recommend-item {
          width: 210px;
          height: 210px;
          margin-right: 37.5px;
          display: flex;
          float: left;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          box-shadow: 0 0 6px #ccc;
          margin-top: 24px;
          margin-bottom: 24px;
          box-sizing: border-box;
          color: #333;
          text-decoration: none;

          &:nth-child(5n) {
            margin-right: 0;
          }

          &:hover {
            border: 1px solid $primary;
          }

          img {
            width: 148px;
            height: 96px;
            object-fit: contain;
            margin-bottom: 20px;
          }

          .title {
            padding: 0 30px;
            font-weight: bold;
            height: 46px;
            width: 100%;
            box-sizing: border-box;
            text-align: center;
          }
        }
      }

      .hot-list {
        height: 272px;
        margin-top: 24px;

        .swiper-container {
          height: 272px;
          min-height: 272px;
        }

        .hot-item {
          width: 210px;
          height: 272px;
          border: 1px solid #ccc;
          box-sizing: border-box;
          color: #333;
          text-decoration: none;
          display: block;

          img {
            width: 100%;
            height: 200px;
            object-fit: contain;
          }

          .title {
            margin-top: 8px;
            padding: 0 30px;
            font-weight: bold;
            height: 46px;
            width: 100%;
            text-align: center;
            box-sizing: border-box;
          }
        }
      }
    }

    &.stat {
      background-image: url("~@/assets/index/highlights.jpg");
      background-size: cover;
      background-position: center;
      padding: 44px 0;

      .title {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 48px;
      }

      .stat-list {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .stat-item {
          display: flex;
          flex-direction: column;
          align-items: center;

          .circle {
            background-position: center;
            background-image: url("~@/assets/index/circle-bg.png");
            background-size: contain;
            width: 164px;
            height: 164px;
            line-height: 164px;
            text-align: center;

            .num {
              font-size: 40px;
              color: #fff;
            }

            .unit {
              font-size: 20px;
              color: #fff;
            }
          }

          .des {
            width: 220px;
            text-align: center;
            line-height: 30px;
            height: 60px;
            font-weight: bold;
            margin-top: 36px;
          }
        }
      }
    }

    .review {
      padding: 64px 15px;

      .review-img {
        background-image: url("~@/assets/index/review-bg.jpeg");
        height: 277px;
        background-position: center;
        background-size: cover;
        position: relative;

        .btn {
          width: 216px;
          height: 36px;
          border-radius: 18px;
          box-shadow: 0 0 8px rgba(255, 255, 255, .8);
          position: absolute;
          bottom: 32px;
          left: calc(50% - 108px);
          background-color: $primary;
          color: #fff;
          line-height: 36px;
          text-align: center;
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
