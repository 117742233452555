import axios from 'axios'
import { Message } from 'element-ui'
// import { getToken, removeToken, setToken } from "@/utils/auth";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 5000
  // withCredentials: true // send cookies when cross-domain requests
})

service.interceptors.request.use(
  (config: any) => {
    // const token = getToken()
    // if (token) {
    //   config.headers.Authorization = `${token}`
    // }
    return config
  },
  (error: any) => {
    Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  (response: any) => {
    // let token = getToken()
    // const res = response.data;
    // if (!token && response.headers && response.headers.authorization) {
    //   setToken(response.headers.authorization);
    // }
    // if (res.retCode === '22009' || res.retCode === '22008') {
    //   Dialog.confirm({
    //     title: '确定登出',
    //     message: '你已被登出，可以取消继续留在该页面，或者重新登录',
    //     confirmButtonText: '重新登录',
    //     cancelButtonText: '取消'
    //   })
    //     .then(() => {
    //       // on confirm
    //       removeToken();
    //       location.reload()
    //     })
    //     .catch(() => {
    //       // on cancel
    //     })
    // } else if (res.retCode == "22007") {
    //   removeToken()
    //   token = setToken(response.headers.authorization)
    //   const config: any = response.config
    //   if (!config.isRetryRequest) {
    //     config.headers.Authorization = token
    //     const url: any = response.config.url
    //     config.url = url.substr(4)
    //     config.isRetryRequest = true
    //     return axios(config)
    //   }
    // } else {
    //   if(response.data){
    //     //指定字段脱密
    //     const jsonEncryptionItems = localStorage.getItem('ENCRYPTION_ITEMS') || '[]';
    //     const encryptionItem = JSON.parse(jsonEncryptionItems);
    //     return response.data;
    //   } else {
    //     return response;
    //   }
    // }
    return response.data
  },
  (error: any) => {
    Message.error(error.retMsg || '请求错误')
    return Promise.reject(error)
  }
)

export default service
