import request from '@/utils/request'

/**
 * @name: 获取 咨询（活动简介、主办方、活动议程）
 * @param {meet_id,type} params
 * @return {*}
 */
export const getSysSetup = (params: any) =>
  request({
    url: '/meeting/sys_setup',
    method: 'get',
    params
  })

/**
* @name: 获取公告详情
* @param {meet_id,type,id,member_id} params
* @return {*}
*/
export const getNewsDetail = (params: any) =>
  request({
    url: '/meeting/new_detail',
    method: 'get',
    params
  })

/**
* @name: 获取图片详情
* @param {id} params
* @return {*}
*/
export const getImageDetail = (params: any) =>
  request({
    url: '/meeting/meeting_live_detail',
    method: 'get',
    params
  })

/**
* @name: 点赞
* @param {meet_id,id,member_id} params
* @return {*}
*/
export const zan = (params: any) =>
  request({
    url: '/meeting/new_zan',
    method: 'get',
    params
  })

/**
* @name: 取消点赞
* @param {meet_id,id,member_id} params
* @return {*}
*/
export const zanCancel = (params: any) =>
  request({
    url: '/meeting/new_zan_del',
    method: 'get',
    params
  })

/**
 * @name: 新闻列表
 * @param {meet_id,id} params
 * @return {*}
 */
export const getNews = (params: any) =>
  request({
    url: '/meeting/get_news',
    method: 'get',
    params
  })
