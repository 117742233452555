<template>
<div class="header">
  <div class="header-top">
    <div class="container">
      <router-link to="/">
        <img class="logo" src="@/assets/logo.png" alt />
      </router-link>
      <div class="right">
      </div>
    </div>
  </div>
  <div class="header-nav">
    <div class="container">
      <el-menu
          :default-active="active"
          :background-color="variables.primary"
          text-color="#fff"
          active-text-color="#fff"
          class="header-menu"
          mode="horizontal">
        <el-menu-item index="home">
          <router-link to="/" class="title">首页</router-link>
        </el-menu-item>
        <el-submenu index="about">
          <template slot="title">关于大会</template>
          <el-menu-item class="submenu-item" :index="`about-${item.id}`" :key="item.id" v-for="item in aboutMenu">
            <router-link :to="`/about?id=${item.id}`">{{
                item.title
              }}</router-link>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="news">
          <template slot="title">新闻中心</template>
          <el-menu-item class="submenu-item" >
            <router-link to="/news_notice?type=1">公告</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/news_notice?type=2">要闻</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/news_notice?type=3">动态</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/news_video">视频</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/news_image">图片</router-link>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="conference">
          <template slot="title">参展参会</template>
          <el-menu-item class="submenu-item" >
            <a :href="`${oldHost}/signup_exhibitor.html`">参展报名</a>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/conference_notice">参会须知</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/conference_schedule">大会日程</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/conference_broadcast">大会现场</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/conference_guide">展区导览</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/conference_guideline">指南指引</router-link>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="online">
          <template slot="title">网上展厅</template>
          <el-menu-item class="submenu-item" >
            <router-link target="_blank" to="/online_3d">3D展厅</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/online_recommend">推荐展商</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/online_display">发展成果展</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/online_industry">产业园展</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/online_organization?eh_id=9">服务机构展</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/online_organization?eh_id=12">用人单位展</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <a target="_blank" href="https://zph.iguopin.com/industrialPark">国聘行动专场</a>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="discuss">
          <template slot="title">对接洽谈</template>
          <el-menu-item class="submenu-item" >
            <router-link to="/discuss_transaction">交易洽谈</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/discuss_notice?id=163">机构宣讲</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/discuss_notice?id=164">项目路演</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/discuss_notice?id=165">地区间座谈</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/discuss_notice?id=166">职业能力座谈</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/online_show">线上路演</router-link>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="forum">
          <template slot="title">论坛会议</template>
          <el-menu-item class="submenu-item" >
            <router-link to="/forum_introduce?meet_id=126&id=167">人力资源服务业高质量发展论坛</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/forum_introduce?meet_id=134&id=170">一带一路人力资源服务发展峰会</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/forum_introduce?meet_id=135&id=173">中国人力资源服务业博士后学术交流会</router-link>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="competition">
          <template slot="title">行业大赛</template>
          <el-menu-item class="submenu-item" >
            <router-link to="/competition_player">选手风采</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/competition_info?id=176">赛事资讯</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/competition_answer">网络答题</router-link>
          </el-menu-item>
          <el-menu-item class="submenu-item" >
            <router-link to="/competition_highlight">精彩花絮</router-link>
          </el-menu-item>
<!--          <el-menu-item class="submenu-item" >-->
<!--            <router-link to="/competition_info?id=177">大赛联系</router-link>-->
<!--          </el-menu-item>-->
        </el-submenu>
        <el-submenu index="service">
          <template slot="title">大会服务</template>
          <el-menu-item class="submenu-item" :index="`service-${item.id}`" :key="item.id" v-for="item in serviceMenu">
            <router-link :to="`/service?id=${item.id}`">{{
                item.title
              }}</router-link>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</div>
</template>

<script>
import { Menu, MenuItem, Submenu } from 'element-ui'
import variables from '@/styles/_variables.scss'
import { getHeadMenu } from '@/api/common'

export default {
  name: 'index',
  components: {
    [Menu.name]: Menu,
    [MenuItem.name]: MenuItem,
    [Submenu.name]: Submenu
  },
  data () {
    return {
      variables,
      showMenu: false,
      active: 'home',
      aboutMenu: [],
      serviceMenu: [],
      isLogin: !!localStorage.getItem('USERINFO'),
      isshowCode: false,
      oldHost: process.env.VUE_APP_OLD_HOST
    }
  },
  created () {
    this.getHeaderMenu(5)
    this.getHeaderMenu(6)
  },
  methods: {
    async getHeaderMenu (type) {
      // 关于大会
      const params = {
        meet_id: 126,
        type,
        iid: 2,
        page: 1,
        page_size: 100
      }
      const res = await getHeadMenu(params)
      // console.info(res.data.arr);
      if (res.code === 0) {
        const list = res.data.arr
        if (type === 6) {
          this.aboutMenu = list
        } else if (type === 5) {
          this.serviceMenu = list
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/_variables.scss";

.header {
  .header-top {
    width: 100%;
    min-width: 1230px;
    box-sizing: border-box;
    .container {
      display: flex;
      height: 150px;
      justify-content: space-between;
      align-items: center;
      .logo {
        height: 80px;
      }
      .right {

      }
    }
  }
  .header-nav {
    width: 100%;
    height: 64px;
    min-width: 1230px;
    background-color: $primary;
    color: #fff;
    box-sizing: border-box;
    font-weight: bold;
    .container {
      .header-menu {
        display: flex;
        justify-content: space-between;
        border-bottom: none;
        width: 100%;
      }
    }
  }
}
</style>
<style lang="scss">
.el-menu {
  height: 64px;
  font-size: 16px;
  &.el-menu--horizontal > .el-menu-item.is-active {
    font-size: 16px;
  }
  .el-menu-item.submenu-item {
    text-align: center;
    height: auto!important;
    line-height: 20px!important;
    padding: 8px 12px!important;
  }
  .el-submenu__title {
    font-size: 16px;
  }
  a {
    text-decoration: none;
    color: #fff;
    text-align: center;
    white-space: normal;
  }
}
.el-menu.el-menu--popup {
  min-width: auto;
  width: 120px;
}
</style>
