import request from '@/utils/request'

// 获取字典项
export const getBanner = (params: any) =>
  request({
    url: '/meeting/get_banner',
    method: 'get',
    params
  })

export const getHeadMenu = (params: any) =>
  request({
    url: '/meeting/get_news1',
    method: 'get',
    params
  })
