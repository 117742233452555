<template>
<div class="footer">
  <div class="container">
    <div class="group-list">
      <div class="group-item">
        <div class="title">主办单位</div>
        <div class="company-list">
          <a
              class="company-item"
              target="_blank"
              :href="item.link_url"
              v-for="item in lists[0]"
              :key="item.id"
          >{{ item.title }}</a
          >
        </div>
      </div>
      <div class="group-item">
        <div class="title">承办单位</div>
        <div class="company-list">
          <a
              class="company-item"
              target="_blank"
              :href="item.link_url"
              v-for="item in lists[1]"
              :key="item.id"
          >{{ item.title }}</a
          >
        </div>
      </div>
      <div class="group-item">
        <div class="title">合作单位</div>
        <div class="company-list">
          <a
              class="company-item"
              target="_blank"
              :href="item.link_url"
              v-for="item in lists[2]"
              :key="item.id"
          >{{ item.title }}</a
          >
        </div>
      </div>
      <div class="group-item">
        <div class="title">媒体渠道</div>
        <div class="company-list">
          <a
              class="company-item"
              target="_blank"
              :href="item.link_url"
              v-for="item in lists[3]"
              :key="item.id"
          >{{ item.title }}</a
          >
        </div>
      </div>
    </div>
    <img src="@/assets/logo-white.png" alt="" class="logo">
    <div class="line"/>
    <div class="bottom">
      <div class="contact-box">
        <img src="@/assets/footer/position.png" alt="">
        <span class="address">重庆国际博览中心（重庆 · 渝北 · 悦来）</span>
        <img src="@/assets/footer/phone.png" alt="">
        <span class="phone">010-86623456</span>
      </div>
      <div class="copyright-box">
        <img src="@/assets/footer/bei.jpeg" alt="">
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010202009251">
          京公安网备 11010202009251号
        </a>
        <a href="https://beian.miit.gov.cn/">
          京ICP备18011856号-6
        </a>
        <span>技术支持：国投人力公司</span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { getBanner } from '@/api/common'

export default {
  name: 'index',
  data () {
    return {
      lists: [[], [], [], []]
    }
  },
  created () {
    this.getCompany()
  },
  methods: {
    async getCompany () {
      const params = {
        position: 10,
        page: 1,
        page_size: 100
      }
      const res = await getBanner(params)
      console.info(res)
      const lists = [[], [], [], []]
      if (res.code === 0) {
        const data = res.data.arr
        for (let i = 0, len = data.length; i < len; i++) {
          const item = data[i]
          lists[item.type1 - 1].push(item)
        }
      }
      this.lists = lists
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  min-width: 1230px;
  padding: 50px 0 30px;
  box-sizing: border-box;
  width: 100%;
  background-image: url("~@/assets/footer/bg.jpg");
  background-size: cover;
  background-position: center;
  text-align: center;
  .group-list {
    color: #fff;
    display: flex;
    justify-content: space-between;;
    .group-item {
      width: 200px;
      text-align: left;
      .title {
        color: white;
        font-size: 18px;
        margin-bottom: 20px;
      }
      .company-list {
        .company-item {
          color: #fff;
          text-decoration: none;
          display: block;
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 10px;
          width: 100%;
        }
      }
    }
  }
  .logo {
    height: 72px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #fff;
  }
  .bottom {
    font-size: 16px;
    margin-top: 24px;
    .contact-box {
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 20px;
        margin-right: 4px;
      }
      .address {
        margin-right: 20px;
      }
    }
    .copyright-box {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      a {
        color: #fff;
        text-decoration: none;
        margin-right: 20px;
      }
    }
  }
}
</style>
